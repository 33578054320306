const isSquareImage = ({
  width,
  height,
  tolerance = 1.1,
}: {
  width: number;
  height: number;
  tolerance?: number;
}) =>
  width &&
  height &&
  Math.max(width, height) / Math.min(width, height) <= tolerance;

export default isSquareImage;
