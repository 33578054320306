import fetchInternalApi from '../backendApi/utils/fetchInternalApi';
import hasError from '../backendApi/utils/hasError';

import { Log } from './types';

const sendClientSideLogs = async (logs: Log[]) => {
  const headers: Record<string, string> = {
    'pictarine-deviceTimestamp': Date.now().toString(),
    Accept: 'application/json',
    'Content-Type': 'application/json',
  };

  if (logs.length === 0) return;
  const data = await fetchInternalApi<undefined>('logs', {
    method: 'POST',
    body: JSON.stringify(logs),
    headers,
  });

  hasError(data || {});
};

export default sendClientSideLogs;
