import { v4 as uuid } from 'uuid';

import {
  DEFAULT_BRIGHTNESS,
  DEFAULT_CONTRAST,
  DEFAULT_SATURATION,
} from '@/components/TemplateEditor/constants';
import { RETRO_ID } from '@/constants';
import isAssetLayer from '@/context/Template/utils/isAssetLayer';
import { Layer, LayerType, Template } from '@/types/template';

const templateId =
  process.env.NEXT_PUBLIC_GCP_ENV === 'production' ? 1064 : 933;
const designId = process.env.NEXT_PUBLIC_GCP_ENV === 'production' ? 917 : 799;
const environement =
  process.env.NEXT_PUBLIC_GCP_ENV === 'production' ? 'prd' : 'int';

const retroPrintTemplate = {
  id: templateId,
  width: 4,
  height: 5.3,
  previewUrl: `https://storage.googleapis.com/picta-${environement}-template-assets/templates/${templateId}/layers/preview.jpg`,
  pages: [
    {
      name: 'Front',
      layers: [
        {
          id: uuid(),
          type: LayerType.Image,
          position: {
            x: 0,
            y: 0,
            width: 1,
            height: 1,
            locked: true,
          },
          url: `https://storage.googleapis.com/picta-${environement}-template-assets/templates/${templateId}/layers/2.png`,
        },
        {
          type: LayerType.Asset,
          id: uuid(),
          position: {
            x: 0.09012875536480687,
            y: 0.06885245901639345,
            width: 0.8197424892703863,
            height: 0.6770491803278689,
            locked: true,
          },
          colors: {
            saturation: DEFAULT_SATURATION, // Adding default values since the backend doesn't provide them
            brightness: DEFAULT_BRIGHTNESS,
            contrast: DEFAULT_CONTRAST,
          },
        },
      ],
    },
  ],
  productIds: [RETRO_ID],
  designId,
};

const getRetroTemplateAssetLayer = (template: Template) => {
  const layers = template.pages.flatMap((page) => page.layers);
  const firstLayer = layers.find(isAssetLayer);

  // FirstLayer should't be undefined
  return firstLayer as Layer<LayerType.Asset>;
};

export { retroPrintTemplate, getRetroTemplateAssetLayer };
