import { Ratio } from '@/types/ratio';
import { Layer, LayerType, Template } from '@/types/template';

const getAssetLayerRatio = (
  layer: Layer<LayerType.Asset>,
  template: Template,
): Ratio => ({
  min: Math.min(
    layer.position.height * template.height,
    layer.position.width * template.width,
  ),
  max: Math.max(
    layer.position.height * template.height,
    layer.position.width * template.width,
  ),
});

export default getAssetLayerRatio;
