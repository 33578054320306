import { ResponseError } from '../types';

export default function hasError<T extends object>(
  data: T | ResponseError,
): data is ResponseError {
  if ('error' in data) {
    const message = `Catched error => status: "${data.code}, error: "${data.error}", message:"${data.message}"`;
    // eslint-disable-next-line no-console
    console.error(message);
    return true;
  }
  return false;
}
