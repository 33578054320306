const isFourByThreeRatio = ({
  width,
  height,
}: {
  width: number;
  height: number;
}) => {
  const ratio = Math.max(width, height) / Math.min(width, height);
  return ratio > 1.3 && ratio < 1.4;
};

export default isFourByThreeRatio;
