import isStorageAvailable from './storageAvailable';

const setLocalStorageItem = <T>(key: string, value: T): boolean => {
  if (!isStorageAvailable) return false;

  const partnerKey = `${process.env.NEXT_PUBLIC_PARTNER}_${key}`;
  localStorage.setItem(partnerKey, JSON.stringify(value));
  return true;
};

export default setLocalStorageItem;
