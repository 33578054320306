import { CropInfo } from '@web-apps/cropper';

import { Ratio } from '@/types/ratio';
import { Orientation } from 'types/orientation';
import round from 'utils/number/round';

const getImageOrientation = (
  width = 0,
  height = 0,
  crop?: CropInfo,
  ratio?: Ratio,
): Orientation => {
  if (ratio && ratio.min === ratio.max) {
    return width && height && width >= height ? 'landscape' : 'portrait';
  }
  if (crop)
    return round(crop.width * width) >= round(crop.height * height)
      ? 'landscape'
      : 'portrait';
  return width && height && width >= height ? 'landscape' : 'portrait';
};

export default getImageOrientation;
