export enum Partners {
  CVS = 'cvs',
  WALMART = 'walmart',
  WALGREENS = 'walgreens',
  DUANEREADE = 'duanereade',
  PICTA = 'picta',
}

export const PARTNERS = [
  Partners.CVS,
  Partners.WALMART,
  Partners.WALGREENS,
  Partners.DUANEREADE,
  Partners.PICTA,
] as const;

export type Partner = (typeof PARTNERS)[number];
