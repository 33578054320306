import { CropInfo } from '@web-apps/cropper';

import { Format, Product } from '@/types/products';

export interface Page {
  // TODO Remove
  name?: string;
  layers: Layer<LayerType>[];
}

export enum PageName {
  Front = 'Front',
  Back = 'Back',
}

export enum StyleAlignment {
  Left = 'left',
  Center = 'center',
  Right = 'right',
}

export enum LayerType {
  Asset = 'asset',
  Image = 'image',
  Text = 'text',
}

export type Layer<T extends LayerType> = {
  type: T;
  id: string;
  position: Position;
} & (T extends LayerType.Asset
  ? {
      crop?: CropInfo;
      storageKey?: string;
      url?: string;
      height?: number;
      width?: number;
      colors: Colors;
    }
  : T extends LayerType.Image
    ? {
        url: string;
      }
    : T extends LayerType.Text
      ? {
          label: string; // placeholder
          text: string; // user input text
          font: Font;
          lineHeight: number;
          style: Style;
        }
      : never);

export interface Position {
  x: number;
  y: number;
  width: number;
  height: number;
  locked: boolean;
}

export interface Font {
  name: string;
  url: string;
  size: number;
  color: Color;
  // WARNING This is a list of colors that can be used as alternatives to the main color and only used to for the cocomelon test right now. Nothing else uses this.
  alternativeColors?: Color[];
}

export interface Color {
  r: number;
  g: number;
  b: number;
  a: number;
}

export interface Colors {
  brightness: number;
  contrast: number;
  saturation: number;
  filter?: {
    id: string;
    matrix: number[];
  };
}

export interface Style {
  alignment: StyleAlignment;
  textTransform?: 'uppercase';
}

export interface Template {
  id: number;
  width: number;
  height: number;
  previewUrl?: string;
  pages: Page[];
  designId?: number;
  productPageId?: string;
}

export interface TemplateProductInfo {
  id: string;
  name: string;
  media: Media[];
  productsWithFormat: ProductsWithFormat[];
  licenseLegalLines?: string;
}

export interface Media {
  format: Format;
  simulations: Simulation[];
  productFormatRadioIcons: string;
  productDetailsImages: ProductDetailsImages[];
}

export interface Simulation {
  url: string;
  width: number;
  position: number;
}

export interface ProductsWithFormat {
  format: Format;
  products: Product[];
}

export interface ProductDetailsImages {
  url: string;
  position: number;
}
