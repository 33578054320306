import isJsonString from './isJsonString';
import isStorageAvailable from './storageAvailable';

const getLocalStorageItem = <T>(key: string, defaultValue: T): T => {
  if (!isStorageAvailable) return defaultValue;

  const partnerKey = `${process.env.NEXT_PUBLIC_PARTNER}_${key}`;
  const stringValue = localStorage.getItem(partnerKey);

  if (stringValue && isJsonString(stringValue)) return JSON.parse(stringValue);
  return defaultValue;
};

export default getLocalStorageItem;
