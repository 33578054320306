import { CropInfo } from '@web-apps/cropper';

import getSelectionSizes from '@/components/Cropper/_utils/getSelectionSizes';
import { Ratio } from '@/types/ratio';
import { Orientation } from 'types/orientation';

const getDefaultImageCrop = ({
  width: imageWidth,
  height: imageHeight,
  ratio,
  orientation,
}: {
  width: number;
  height: number;
  ratio?: Ratio;
  orientation?: Orientation;
}): CropInfo => {
  const { width: selectionWidth, height: selectionHeight } = getSelectionSizes({
    imageWidth,
    imageHeight,
    ratio,
    orientation,
  });

  // auto center crop default
  // const topPos = (imageHeight - selectionHeight) / 2;

  // top center crop default
  const topPos = 0;
  const leftPos = (imageWidth - selectionWidth) / 2;
  const cropCoordinates = {
    x1: leftPos / imageWidth,
    y1: topPos / imageHeight,
    x2: (leftPos + selectionWidth) / imageWidth,
    y2: (topPos + selectionHeight) / imageHeight,
  };

  return {
    x: cropCoordinates.x1,
    y: cropCoordinates.y1,
    width: Math.abs(cropCoordinates.x2 - cropCoordinates.x1),
    height: Math.abs(cropCoordinates.y1 - cropCoordinates.y2),
  };
};

export default getDefaultImageCrop;
